.about-wrapper {
    position: fixed !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(85, 85, 85, 0.5);

    .kf-logo {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 50%;
        top: 15%;
        margin-left: -20px;
    }

    .about-text {
        position: absolute;
        width: 282px;
        height: 125px;
        left: calc(50% - 141px);
        top: 25%;
        text-align: center;
        color: #ffffff;
        font-size: 20px;

        .kf-credit-line {
            font-family: "Calibre-Regular";
            font-size: inherit;
            margin-bottom: 40px;
        }

        .app-version {
            font-family: "Calibre-Regular";
            font-size: inherit;
            margin-bottom: 40px;
        }

        .terms-and-conditions {
            a {
                color: #ffffff;
                bottom: 0;
                text-decoration: none;
                padding-bottom: 5px;
                border-bottom: 2px solid #fff;
            }
        }
    }
}
