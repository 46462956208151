#slider-overlay {
    height: 540px;
    width: 100%;
    background: transparent;
    position: relative;
    z-index: 2;
    pointer-events: none;
}

.slider-wrapper {
    height: 540px;
    width: 100%;
    overflow: hidden;
    //position: relative;
    transition: position 300ms ease;

    .slider-background {
        z-index: 1;
        transform: scale(1.2);
    }

    .slider-header {
        line-height: 25px;
        z-index: 99;
        position: absolute;
        top: 50px;
        width: 200px;
        left: calc(50% - 100px);
        color: #fff;
    }

    .slider-container {
        position: absolute;
        width: 100%;
        top: 35px;
        z-index: 9999 !important;
        height: 100%;

        /* Slick sider customization for scale and animation*/

        // Need position relative and a base z index
        .slick-slide {
            position: relative;
            z-index: 6;
        }
        // Set center to high zindex
        .slick-active {
            z-index: 999; // or any high #
        }
        // Target slide after center to keep stacking
        .slick-active + .slick-slide {
            z-index: 8;
        }

        .slick-list {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }

        .slick-slide {
            img {
                opacity: 0.7;
                margin: 15px;
            }
        }

        .slick-active img {
            -moz-transform: scale(1.13);
            -ms-transform: scale(1.13);
            -o-transform: scale(1.13);
            -webkit-transform: scale(1.13);
            transform: scale(1.13);
            opacity: 1;
            transition: all 0.2s ease-in-out;
        }
    }

    .footer-text {
        line-height: 24px;
        font-size: 16px;
        text-align: center;
        position: absolute;
        bottom: 125px;
        width: 260px;
        left: calc(50% - 130px);
        display: flex;
        justify-content: space-between;

        color: rgba(255, 255, 255, 0.7);

        a {
            text-decoration: none;
            color: inherit;
        }
    }
}
