.content-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 24px;
  padding-bottom: 16px;

  &__title-block {
    padding-bottom: 16px;

    &__title {
      font-size: 28px;
      font-weight: bold;
    }

    &__subtitle {
      font-size: 22px;
      color:rgba(0, 0, 0, 0.5);
    }
  }

  &__text {
    ul, 
    ol {
      padding-left: 40px;
    }

    h2 {
      text-align: unset;
    }

    blockquote {
      margin-left: 1rem;
      padding: 0.25rem 1rem;
      border-left: 2px solid #8c8c8c;
    }

    code {
      color: #f75033;
    }

    table {
      margin: 2rem 0;
      
      td {
        border: 1px solid #8c8c8c;
        padding: 0.6rem;
        
        p {
          margin-bottom: unset;
        }
      }
    }
  }

  &__image {
    width: 100%;
    margin: 2rem 0;

    img {
      max-width: 100%;
    }
  }

  &__fade-image {
    width: 100%;
    margin: 2rem 0;
    top: 0;
    left: 0;
  }

  &__comparison {
    margin: 2rem 0;

    &__caption {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;

      .caption-left {
        text-align: left;
        flex-grow: 1;
      }

      .caption-right {
        text-align: right;
        flex-grow: 1;
      }
    }
    &-fade {
      flex-direction: column;
    }
  }

  &__video {
    margin: 2rem 0;
  }

  &__image-carousel {
    &__item {
      width: fit-content;
      margin: auto;

      figcaption {
        margin-top: 0.5rem;
      }
    }

    li.alice-carousel__dots-item {
      background-color: rgba(0, 0, 0, 0.2);

      &.__active {
        background-color: #f75033;
      }
    }

    .alice-carousel {
      &__dots {
        margin: 0.5rem auto;
        max-width: calc(100% - 50px);
      }

      &__prev-btn,
      &__next-btn {
        display: inline-block;
        width: 25px;
        position: absolute;
        bottom: -6px;
        padding-top: 0;
        padding-bottom: 0;

        &-item {
          padding-top: 0;
          padding-bottom: 0;
        }

        p {
          color: rgba(0, 0, 0, 0.5);
          font-size: 24px;
        }
      }

      &__prev-btn {
        left: 0;
      }

      &__next-btn {
        right: 0;
      }
    }
  }
}

.fade-one {
  position: absolute;
  opacity: 1;
  animation: fadeAnimation 5s infinite ease-in-out;
}

@keyframes fadeAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}