.exhibition-obj {
  height: 100vh;

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    height: var(--doc-height);

    &__result {
      margin-bottom: 30vh;
      position: relative;
      padding-left: 0;
      padding-right: 0;

      .artwork-top-bg {
        filter: blur(20px);
        height: 60px;
        transform: scale(1.1);
      }

      .card {
        border: none;
        border-radius: 24px;
        text-align: center;
        background-color: transparent;
        padding-bottom: 70px;

        .card-top-container {
          position: relative;
          height: auto !important;
          background-color: #fff;
          border-radius: 24px 24px 0 0;
          z-index: 2;
          padding-top: 2px;
        }

        .card-img-container {
          position: absolute;
          filter: blur(25px);
          transform: scale(1.2);
          min-height: 90vh;
          height: calc(100% + 100px);

          img {
            height: 100%;
            z-index: 100;
          }
        }

        .card-img-overlay {
          padding: 0px 60px;
          height: auto;
          position: relative;

          .card-header {
            color: #f75033;
            background-color: #fff;
            font-size: 18px;
            text-transform: uppercase;
            border: none;
          }

          .card-img-result {
            position: relative;
            z-index: 1;
            pointer-events: none;

            img {
              width: 80%;
            }
          }

          .card-artist {
            position: relative;
            padding-top: 30px;
            text-align: center;
            color: gray;
            opacity: 1;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            letter-spacing: 0.75px;
            font-family: "Calibre-Regular";
          }

          .card-title {
            position: relative;
            margin: 0 -15px;
            text-align: center;
            color: rgba(0.21, 0.21, 0.21, 1);
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0.11px;
            font-family: "Calibre-Regular", sans-serif;
          }
        }

        .card-body {
          padding: 25px;
          color: #282828;
          text-align: left;
          background: #fff;

          .card-text {
            padding-bottom: 30px;
            font-family: "Calibre-Regular", sans-serif;
          }

          .card-info {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }

          .detail-table {
            width: 100%;

            .item-label {
              font-family: "Calibre-Regular", sans-serif;
              line-height: 30px;
              font-size: 17px;
              letter-spacing: 0.01em;
              color: #6e6e6e;
              opacity: 0.8;
              vertical-align: top;
              width: 30%;
            }

            .item-info {
              font-family: "Calibre-Regular", sans-serif;
              line-height: 18px;
              padding-top: 6px;
              font-size: 17px;
              letter-spacing: 0.01em;
              color: #282828;
              vertical-align: top;
            }
          }

          .share-wrapper {
            pointer-events: auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-evenly;

            .btn-share-result {
              width: fit-content;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 45px;
              border: 2px solid rgba(0, 0, 0, 0.5);
              border-radius: 100px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;
              padding: 20px;

              img {
                padding-right: 10px;
                height: 16px;
              }

              .text-share {
                line-height: 24px;
                font-size: 16px;
                color: #6e6e6e;
              }
            }
          }

          .text-left {
            text-align: left;
          }

          .text-right {
            text-align: right;
          }
        }
      }

      #card-2__trigger {
        height: 55vh;
        width: 100vw;
        position: absolute;
        bottom: -20vh;
      }
    }

    &__email {
      position: fixed;
      // bottom: calc(0vh - calc(390px + env(safe-area-inset-bottom)));
      // this bit works on my phone in each browser but who knows if it will elsewhere...
      bottom: calc( (var(--doc-height) / -2 ) - var(--email-height) - env(safe-area-inset-bottom));
      width: 100vw;
      transition: all .25s ease .1s;
      z-index: 10;

      &.open {
        bottom: 0px;
        transition: all .25s ease .1s;
      }

      &.completed {
        bottom: calc(0vh - (345px + env(safe-area-inset-bottom)));
        transition: all .25s ease .1s;
      }
    }

    .scan-button {
      transition: position 1s ease 1s;

      &.floating {
        transition: position 1s ease 1s;
      }
    }
  }
}