.scrollmagic-pin-spacer-pt,
.scrollmagic-pin-spacer {
    pointer-events: none;
}

.story-title-bar {
    position: absolute;
    top: 0;
    height: 65px;
    z-index: 1000;
    color: #fff;
    width: 100%;
    font-family: "Calibre-Regular", sans-serif;
    font-size: 18px;
    vertical-align: middle;
    line-height: 50px;
    display: flex;
    //justify-content: space-between;
    padding: 0 25px;
    pointer-events: none;

    .story-title {
        font-weight: bold;
        font-family: inherit;
        text-transform: uppercase;
        font-family: "Calibre-Medium", sans-serif;
        line-height: 1.2;
        padding-right: 10px;
    }

    .language-dropdown {
        margin-left: auto;
        .dd-wrapper {
            margin-left: auto;
            max-width: 80px;
        }

        .dd-header {
            background-color: transparent;
            color: #fff;
            border: 2px solid rgba(255, 255, 255, 0.85);
            pointer-events: auto;
        }

        .dd-list {
            bottom: 0% !important;
            position: relative;
            top: 5px;
            line-height: 16px;
            right: 35px;
            border: none;
            text-transform: none;
        }
    }
}

.story-item {
    font-family: "Calibre-Regular", sans-serif;
    width: 100vw;
    background-color: transparent;
    border: none;
    //transform: translate3d(0px, -67px, 0px);

    .story-title {
        margin: 17px auto;
        line-height: 1.2;
    }

    .card-img-top {
        border-radius: 24px 24px 0px 0px;
    }

    .card-img-overlay {
        padding-top: 100px;
        text-align: left;
        bottom: unset;
        // position: relative;
        // height: 100%;

        .intro {
            position: absolute;
        }

        .story-question {
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            padding-top: 40px;
            font-family: "Calibre-Medium", sans-serif;
        }

        .story-title {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding-top: 100px;
            font-family: "Calibre-Medium", sans-serif;
            line-height: 26px;
        }

        .story-name {
            width: 292px;
            color: #ffffff;
            font-family: "Calibre-Medium", sans-serif;
            font-size: 40px;
            font-weight: bold;
            line-height: 44px;
            margin: 50px 0;
        }

        .story-text {
            color: #fff;
            font-family: "Calibre-Regular", sans-serif;
            font-size: 20px;
            line-height: 26px;

            p {
                color: #fff;
                font-family: "Calibre-Regular", sans-serif;
                font-size: 20px;
                line-height: 26px;
                font-weight: 300;
            }
        }

        .story-footer {
            color: #fff;
            font-family: "Calibre-Regular", sans-serif;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.07px;
        }
    }
}

#search-result {
    // height: 90vh;
    // margin-bottom: -170px;
    z-index: 0;
    height: 100vh;
    overflow: visible;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
    background-color: black;
}

#story-title-bar {
    position: fixed;
    // opacity: 0;
    top: 10px;
    left: 0px;
    width: 100%;
    height: 69px;
    z-index: 100;
    // background-color: rgba(100,100,100,0.2);
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.content-mask {
    -webkit-mask-image: linear-gradient(
        to bottom,
        transparent 69px,
        black 80px,
        black 100%
    );
    mask-image: linear-gradient(
        to bottom,
        transparent 69px,
        black 80px,
        black 100%
    );
    // background: linear-gradient(to bottom, transparent, black, black, black, black, black);
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    // border: 2px solid red;
}
